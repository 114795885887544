<template>
  <b-navbar
    style="background-color: white; box-shadow: none"
    class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  >
    <b-container class="text-light text-center">
      <b-col>
        <b-row class="">
          <b-col md="4" class="">
            <!-- <b-button variant="white" size="sm" :to="{ name: 'payment-ebook', params: { id: ebookDetail.slug } }"><feather-icon icon="ArrowLeftIcon" class="mr-50" />Konfirmasi Pembayaran</b-button></b-col
          > -->
            <b-button variant="white" size="sm" @click="$router.go(-1)"
              ><feather-icon icon="ArrowLeftIcon" class="mr-50" />Konfirmasi
              Pembayaran</b-button
            ></b-col
          >
          <b-col md="4" class=""
            ><b-img :src="img" rounded width="64px" style="align: center"
          /></b-col>
        </b-row>
      </b-col>
    </b-container>
  </b-navbar>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BNav,
  BNavItem,
  BFormRadio,
  BImg,
  BLink,
  BNavbarNav,
  BNavbar,
  BButton,
} from "bootstrap-vue";
// import Bookmarks from "./components/Bookmarks.vue";
// import Locale from "./components/Locale.vue";
// import SearchBar from "./components/SearchBar.vue";
// import DarkToggler from "./components/DarkToggler.vue";
// import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./NotificationDropdown.vue";
import UserDropdown from "./UserDropdown.vue";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BNav,
    BNavItem,
    BFormRadio,
    BImg,
    BLink,
    BNavbar,
    BButton,
    // Navbar Components
    BNavbarNav,
    // Bookmarks,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      img: require("@/assets/images/elp/belajar.png"),
      selected: "center",
    };
  },
  computed: {
    ebookDetail() {
      return this.$store.state.ebook.detail;
    },
  },
};
</script>
